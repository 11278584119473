import { h, Component } from 'preact';
import * as style from './forms.scss';

interface FormsProps {}

interface FormsState {}

export class Forms extends Component<FormsProps, FormsState> {
  public render({}: FormsProps, {}: FormsState) {
    return (
      <div class={style.formsWrap}>
        <iframe src={'/forms/iBerry_Book_Rating_Form_v101.pdf'}></iframe>
      </div>
    );
  }
}
