import { h, FunctionalComponent } from 'preact';
import * as style from './mainSideDrwr.scss';
import { useState, useEffect } from 'preact/hooks';
import { Link } from 'wouter-preact';

interface MainSideDrawerProps {
  onUserClick(): void;
}

export const MainSideDrawer: FunctionalComponent<MainSideDrawerProps> = () => {
  const [drawerOut, setDrawerOut] = useState(false);
  const msdClass = drawerOut
    ? `${style.sideDrawerWrap} ${style.allOut}`
    : style.sideDrawerWrap;

  //https://stackoverflow.com/questions/53090432/react-hooks-right-way-to-clear-timeouts-and-intervals
  useEffect(() => {
    let timer1 = setTimeout(() => setDrawerOut(true), 700);
    return () => {
      // clear Timeout on component unmount (becomes ComponentWillUnmount)
      clearTimeout(timer1);
    };
  }, []); //MT array means useEffect will run only one time, at Component mount

  return (
    <div class={msdClass}>
      <div class={style.floatersMenu}>
        <div class={`${style.menuItemDiv} ${style.menuBooks}`}>
          <Link href='/books'>
            <img src={'/img/menuButtons/books.png'} />
          </Link>
          <div class={style.menuLbl}>Books&nbsp;</div>
        </div>
        <div class={`${style.menuItemDiv} ${style.menuKids}`}>
          <Link href='/kids'>
            <img src={'/img/menuButtons/kids.png'} />
            <div class={style.menuLbl}>
              <br />
              Kids&nbsp;&nbsp;
            </div>
          </Link>
        </div>
        <div class={`${style.menuItemDiv} ${style.menuForms}`}>
          <Link href='/forms'>
            <img src={'/img/menuButtons/forms.png'} />
            <div class={style.menuLbl}>
              <br />
              Forms
            </div>
          </Link>
        </div>
        <div class={`${style.menuItemDiv} ${style.menuRateSys}`}>
          <Link href='/ratingsystem'>
            <img src={'/img/menuButtons/ratesys.png'} />
            <div class={style.menuLbl}>
              Rating
              <br />
              System
            </div>
          </Link>
        </div>
        <div class={`${style.menuItemDiv} ${style.menuMember}`}>
          <Link href='/members'>
            <img src={'/img/menuButtons/memb.png'} />
            <div class={`${style.menuLbl} ${style.menuLblSmall}`}>Members</div>
          </Link>
        </div>
        <div class={`${style.menuItemDiv} ${style.menuAuthor}`}>
          <Link href='/authors'>
            <img src={'/img/menuButtons/author.png'} />
            <div class={style.menuLbl}>
              <br />
              Author
            </div>
          </Link>
        </div>
        <div class={`${style.menuItemDiv} ${style.menuProfile}`}>
          <Link href='/profile'>
            <img src={'/img/menuButtons/profile.png'} />
            <div class={style.menuLbl}>
              <br />
              Profile
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
