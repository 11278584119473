import { createContext } from 'preact';

export enum PrivLevelEnum {
  normal = 1,
  moderator,
  siteAdmin,
}

export interface UserData {
  username: string | undefined;
  authenticated: boolean | undefined;
  privLevel: PrivLevelEnum;
}

export const Authentication = createContext<UserData>({
  username: undefined,
  authenticated: undefined,
  privLevel: PrivLevelEnum.normal,
});
