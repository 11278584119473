import { h, FunctionalComponent } from 'preact';
import * as style from './rateBook.scss';
import { RatingSlider } from './RatingSlider';
import { SliderKey } from './RateBook';

interface SideRightProps {
  bid: string;
  onSlideSet(name: SliderKey, value: number | number[]): void;
  sliders: { [key: string]: number | number[] };
}

export const SideRight: FunctionalComponent<SideRightProps> = ({
  bid,
  sliders,
  onSlideSet,
}) => {
  return (
    <div class={style.sideRightComponentWrap}>
      <div class={style.blurbRight}>Your Ratings:</div>
      <div class={style.legendDiv}>
        <div class={style.legendSpacer}>&nbsp;</div>
        <div class={style.sliderLegend}>
          <div class={style.legendItem}>0</div>
          <div class={style.legendItem}>1&nbsp;&nbsp;&nbsp;</div>
          <div class={style.legendItem}>2</div>
          <div class={style.legendItem}>3</div>
        </div>
      </div>
      <RatingSlider
        title={'Substance Abuse'}
        onSlideSet={onSlideSet}
        value={sliders.slideSubstabuse}
        laKey={'slideSubstabuse'}
      />
      <RatingSlider
        title={'Gore'}
        onSlideSet={onSlideSet}
        value={sliders.Gore}
        laKey={'slideGore'}
      />
      <RatingSlider
        title={'Horror'}
        onSlideSet={onSlideSet}
        value={sliders.Horror}
        laKey={'slideHorror'}
      />
      <RatingSlider
        title={'Profanity'}
        onSlideSet={onSlideSet}
        value={sliders.Profanity}
        laKey={'slideProfanity'}
      />
      <RatingSlider
        title={'Sexual Content'}
        onSlideSet={onSlideSet}
        value={sliders.SexCon}
        laKey={'slideSexCon'}
      />
      <RatingSlider
        title={'Violence'}
        onSlideSet={onSlideSet}
        value={sliders.Violence}
        laKey={'slideViolence'}
      />
      <RatingSlider
        title={'Suspense'}
        onSlideSet={onSlideSet}
        value={sliders.Suspense}
        laKey={'slideSuspense'}
      />
      <RatingSlider
        title={'Death'}
        onSlideSet={onSlideSet}
        value={sliders.Death}
        laKey={'slideDeath'}
      />
      <RatingSlider
        title={'Murder'}
        onSlideSet={onSlideSet}
        value={sliders.Murder}
        laKey={'slideMurder'}
      />
      <RatingSlider
        title={'Disturbing Content'}
        onSlideSet={onSlideSet}
        value={sliders.DisturbCon}
        laKey={'slideDisturbCon'}
      />
      <RatingSlider
        title={'Immorality Involving Minors'}
        onSlideSet={onSlideSet}
        value={sliders.ImmoMinors}
        laKey={'slideImmoMinors'}
      />
      <RatingSlider
        title={'Suggestive Situations'}
        onSlideSet={onSlideSet}
        value={sliders.SuggSits}
        laKey={'slideSuggSits'}
      />
      <RatingSlider
        title={'Alcohol Abuse'}
        onSlideSet={onSlideSet}
        value={sliders.Alcohol}
        laKey={'slideAlcohol'}
      />
      <RatingSlider
        title={'Sensuality'}
        onSlideSet={onSlideSet}
        value={sliders.Sensuality}
        laKey={'slideSensuality'}
      />
      <RatingSlider
        title={'Immoral Behavior'}
        onSlideSet={onSlideSet}
        value={sliders.ImmoBehav}
        laKey={'slideImmoBehav'}
      />
      <RatingSlider
        title={'Religious Content'}
        onSlideSet={onSlideSet}
        value={sliders.ReligCon}
        laKey={'slideReligCon'}
      />
      <RatingSlider
        title={'Inappropriate Images'}
        onSlideSet={onSlideSet}
        value={sliders.InappImg}
        laKey={'slideSubstabuse'}
      />
    </div>
  );
};
