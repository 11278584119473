import { h, FunctionalComponent } from 'preact';
import * as style from './books.scss';
import { BookTitle } from '../../Components/types';

/* Called by BookResults.tsx to display each "Book" entry in the returned list of books */

interface BookProps {
  book: BookTitle;
  showBookCover: boolean;
  onViewBookRating(bid: number): void;
}

export const Book: FunctionalComponent<BookProps> = ({
  book,
  showBookCover,
  onViewBookRating,
}) => {
  // console.log({ book });
  const handleTitleClick = (d: number) => {
    onViewBookRating(book.bid);
  };
  return (
    <div class={style.bookDiv}>
      {showBookCover && (
        <div class={style.cover}>
          <img src={`/img/cover/cov-${book.bid}.jpg`} />
        </div>
      )}
      <div class={style.rating}>
        <div>
          <img src='/img/widgets/up.png' />
        </div>
        <div style={'margin-bottom:2px;'}>{book.rating}</div>
        <div>
          <img src='/img/widgets/dn.png' />
        </div>
      </div>
      <div class={style.details}>
        <div class={style.titleDiv} onClick={() => handleTitleClick(book.bid)}>
          {book.title}
        </div>
        <div class={style.detailsInfo}>
          <div class={style.actions}>
            <img src='/img/widgets/grey_plus.jpg' />
          </div>
          <div class={style.author}>{book.author}</div>
          <div class={style.isbn}>ISBN: {book.isbn}</div>
        </div>
        <div class={style.ratingStars}>
          <img />
        </div>
      </div>
    </div>
  );
};
