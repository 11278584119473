import { h, FunctionalComponent } from 'preact';
import * as style from './admin.scss';
import { useState, useEffect } from 'preact/hooks';
import { get, post } from '../../../utils/ajax';
import { scratchpadDropdowns } from '../../Components/types';
//import { useState } from 'preact/hooks';
//import { Redirect } from 'wouter-preact';

interface AdminProps {
  doSave(): void;
}

export const Admin: FunctionalComponent<AdminProps> = ({ doSave }) => {
  //const [varName, setvarName] = useState('');
  const myPoints = 'not done yet';
  const [genres, setGenres] = useState<string[] | undefined>(undefined);
  const [tags, setTags] = useState<string[] | undefined>(undefined);
  const [tagsSide, setTagsSide] = useState<string[] | undefined>(undefined);
  useEffect(() => {
    get<scratchpadDropdowns>(`/allScratchpadsForAdminUpdate`)
      .then((d) => {
        setTags(d.tags_cannonical);
        setTagsSide(d.tags_sidebar);
        setGenres(d.genres);
      })
      .catch(() => {
        console.log(
          `Error: Oops. We had an error condition fetching the data from the scratchpad table.`
        );
      });
  }, ['genres', 'tags']);
  const handleTextareaBlur = (e: Event, name: string) => {
    const value = (e.srcElement as HTMLTextAreaElement).value;
    const val = value.replace(/\n/g, '|');
    // console.log(`Admin.tssx tags (post-replace): ${tags}`);
    post<string>('/updateScratchpadAdmin', { val, name });
  };
  return (
    <div class={style.adminWrap}>
      <div class={style.pageWrapper}>
        <div class={style.pageForm}>
          <div class={style.dropDowns}>
            <div class={style.genreDropDown}>
              <div class={style.ddTitle}>Genres Dropdown:</div>
              <textarea onBlur={(e: Event) => handleTextareaBlur(e, 'genres')}>
                {genres?.join('\n')}
              </textarea>
            </div>
            <div class={style.tagsDropDown}>
              <div class={style.ddTitle}>Tags Dropdown:</div>
              <textarea
                onBlur={(e: Event) => handleTextareaBlur(e, 'tags_cannonical')}
              >
                {tags?.join('\n')}
              </textarea>
            </div>
            <div class={style.tagsSidebar}>
              <div class={style.ddTitle}>SideDrawer Tags:</div>
              <textarea
                onBlur={(e: Event) => handleTextareaBlur(e, 'tags_sidebar')}
              >
                {tagsSide?.join('\n')}
              </textarea>
            </div>
            <div class={style.variablesDiv}>
              <div class={style.ddTitle}>
                My Points: <span class={style.myPoints}>{myPoints}</span>
              </div>

              <div class={style.ddTitle}>Session Variables:</div>
              <textarea>Not Done Yet</textarea>
            </div>
          </div>
          <div class={style.groupBtns}>
            <div class={style.topBtns}>
              <button class={style.btnUpDn}>Zero All Up/Down Votes</button>
              <button class={style.btnStars}>Zero All Star Ratings</button>
              <button class={style.btnDnVoteSldrs}>
                Zero All Down Vote Sliders
              </button>
              <button class={style.btnLetters}>Reset All Letter Ratings</button>
              <button class={style.btnPoints}>Reset All User Points</button>
            </div>
            <div class={style.botBtns}></div>
          </div>
          <div class={style.submitDiv}>
            <button class={style.btnSubmit} onClick={doSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
