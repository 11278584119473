import { h, Component } from 'preact';
import * as style from './rateBook.scss';
import { Authentication, UserData } from '../../store/store';
import { SideLeft } from './SideLeft';
import { SideRight } from './SideRight';
import { TextArea } from '../../Components/TextArea/TextArea';
import { post, get } from '../../../utils/ajax';
import { debounce } from '../../../utils/debounce';

interface RateBookProps {}

export type SliderKey = Exclude<
  keyof RateBookState,
  | 'isbn'
  | 'author'
  | 'title'
  | 'publisher'
  | 'year'
  | 'genre'
  | 'format'
  | 'tags'
  | 'userComment'
  | 'charsRemaining'
  | 'selectTags'
  | 'selectGenre'
>;

export type FieldKey = Extract<
  keyof RateBookState,
  | 'isbn'
  | 'author'
  | 'title'
  | 'publisher'
  | 'year'
  | 'genre'
  | 'format'
  | 'tags'
>;

export interface RateBookState {
  isbn: string;
  author: string;
  title: string;
  publisher: string;
  year: string;
  genre: string;
  format: string;
  tags: string;
  userComment: string;
  charsRemaining: number;
  slideSubstabuse: number | number[];
  slideGore: number | number[];
  slideHorror: number | number[];
  slideProfanity: number | number[];
  slideSexCon: number | number[];
  slideViolence: number | number[];
  slideSuspense: number | number[];
  slideDeath: number | number[];
  slideMurder: number | number[];
  slideDisturbCon: number | number[];
  slideImmoMinors: number | number[];
  slideSuggSits: number | number[];
  slideAlcohol: number | number[];
  slideSensuality: number | number[];
  slideImmoBehav: number | number[];
  slideReligCon: number | number[];
  slideInappImg: number | number[];
  selectTags: string[];
  selectGenre: string[];
  selectFormat: string[];
}

export class RateBook extends Component<RateBookProps, RateBookState> {
  public static contextType = Authentication;
  public constructor(props: RateBookProps) {
    super(props);
    this.state = {
      isbn: '',
      author: '',
      title: '',
      publisher: '',
      year: '',
      genre: 'Choose Genre: *',
      format: 'Choose Format: *',
      tags: 'Choose a Tag: *',
      userComment: '',
      charsRemaining: 140,
      slideSubstabuse: 0,
      slideGore: 0,
      slideHorror: 0,
      slideProfanity: 0,
      slideSexCon: 0,
      slideViolence: 0,
      slideSuspense: 0,
      slideDeath: 0,
      slideMurder: 0,
      slideDisturbCon: 0,
      slideImmoMinors: 0,
      slideSuggSits: 0,
      slideAlcohol: 0,
      slideSensuality: 0,
      slideImmoBehav: 0,
      slideReligCon: 0,
      slideInappImg: 0,
      selectTags: [],
      selectGenre: [],
      selectFormat: [],
    };
  }
  public render(
    {}: RateBookProps,
    {
      isbn,
      author,
      title,
      publisher,
      year,
      genre,
      format,
      tags,
      userComment,
      charsRemaining,
      slideSubstabuse,
      slideGore,
      slideHorror,
      slideProfanity,
      slideSexCon,
      slideViolence,
      slideSuspense,
      slideDeath,
      slideMurder,
      slideDisturbCon,
      slideImmoMinors,
      slideSuggSits,
      slideAlcohol,
      slideSensuality,
      slideImmoBehav,
      slideReligCon,
      slideInappImg,
      selectTags,
      selectFormat,
      selectGenre,
    }: RateBookState
  ) {
    const { authenticated } = this.context as UserData;
    return authenticated ? (
      <div class={style.rateBookWrap}>
        <div class={style.formWrapper}>
          <div class={style.rateForm}>
            <div class={style.title}>Add a New Book Rating</div>
            <div class={style.formBody}>
              <div class={style.sideLeft}>
                <SideLeft
                  onUpdateData={this.handleUpdateBookdata}
                  fields={{
                    isbn,
                    author,
                    title,
                    publisher,
                    year,
                    genre,
                    format,
                    tags,
                  }}
                  selects={{ selectTags, selectGenre, selectFormat }}
                />
                <div class={style.userComment}>
                  <div class={style.blurb}>
                    <div class={style.notice}>Comments: (140 char max)</div>
                    <div class={style.remaining}>{charsRemaining}</div>
                  </div>
                  <TextArea
                    rows={6}
                    value={userComment}
                    onType={debounce(this.handleUpdateUserComment, 200)}
                    placeholder={'Optional comment (140 char max)'}
                  />
                </div>
                <div class={style.buttons}>
                  <button onClick={this.handleSubmit}>Submit</button>
                </div>
              </div>
              <div class={style.sideRight}>
                <SideRight
                  bid={'1'}
                  sliders={{
                    slideSubstabuse,
                    slideGore,
                    slideHorror,
                    slideProfanity,
                    slideSexCon,
                    slideViolence,
                    slideSuspense,
                    slideDeath,
                    slideMurder,
                    slideDisturbCon,
                    slideImmoMinors,
                    slideSuggSits,
                    slideAlcohol,
                    slideSensuality,
                    slideImmoBehav,
                    slideReligCon,
                    slideInappImg,
                  }}
                  onSlideSet={this.handleSliderSet}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      // <meta http-equiv='refresh' content="0;URL='http://localhost:3120'" />
      // <Redirect to='/' />
      <div class={style.unAuth}>Please login to add new book ratings.</div>
    );
  }
  public componentDidMount(): void {
    get('/getAllSelects').then((d) => {
      // const test = [...d.genres];
      // console.log({ test });
      d.genres.unshift('Choose Genre: *');
      d.formats.unshift('Choose Format: *');
      d.tags_cannonical.unshift('Choose a Tag: *');
      const selectGenre = d.genres;
      const selectFormat = d.formats;
      const selectTags = d.tags_cannonical;
      this.setState({ selectTags, selectGenre, selectFormat });
    });
  }
  private handleSliderSet = (name: SliderKey, value: number | number[]) => {
    this.setState({ [name]: value });
  };
  private handleUpdateBookdata = (name: string, value: string) => {
    this.setState({ [name]: value });
  };
  private handleUpdateUserComment = (value: string) => {
    const len = value.length;
    const charsRemaining = 140 - len;
    this.setState({ charsRemaining, userComment: value });
  };
  private handleSubmit = () => {
    post('/updateBookRating', this.state);
  };
}
