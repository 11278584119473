import { h, FunctionalComponent } from 'preact';
import * as style from './bookWithRatingDisplay.scss';
import { RatingSliderDisplay } from './RatingSliderDisplay';
import { BookRatingCats } from '../../Components/types';

interface SideRightDisplayProps {
  sliders: BookRatingCats;
  rating: string;
}

export const SideRightDisplay: FunctionalComponent<SideRightDisplayProps> = ({
  sliders,
  rating,
}) => {
  return (
    <div class={style.sideRightDisplayComponentWrap}>
      <div class={style.blurbRight}>
        <span>Book Rating: </span>
        <span class={style.rating}>{rating}</span>
      </div>
      <div class={style.legendDiv}>
        <div class={style.legendSpacer}>&nbsp;</div>
        <div class={style.sliderLegend}>
          <div class={style.legendItem}>0</div>
          <div class={style.legendItem}>1&nbsp;&nbsp;&nbsp;</div>
          <div class={style.legendItem}>2</div>
          <div class={style.legendItem}>3</div>
        </div>
      </div>
      <RatingSliderDisplay
        title={'Substance Abuse'}
        value={sliders.slideSubstabuse}
      />
      <RatingSliderDisplay title={'Gore'} value={sliders.slideGore} />
      <RatingSliderDisplay title={'Horror'} value={sliders.slideHorror} />
      <RatingSliderDisplay
        title={'Profanity'}
        value={+sliders.slideProfanity}
      />
      <RatingSliderDisplay
        title={'Sexual Content'}
        value={sliders.slideSexCon}
      />
      <RatingSliderDisplay title={'Violence'} value={sliders.slideViolence} />
      <RatingSliderDisplay title={'Suspense'} value={sliders.slideSuspense} />
      <RatingSliderDisplay title={'Death'} value={sliders.slideDeath} />
      <RatingSliderDisplay title={'Murder'} value={sliders.slideMurder} />
      <RatingSliderDisplay
        title={'Disturbing Content'}
        value={sliders.slideDisturbCon}
      />
      <RatingSliderDisplay
        title={'Immorality Involving Minors'}
        value={sliders.slideImmoMinors}
      />
      <RatingSliderDisplay
        title={'Suggestive Situations'}
        value={sliders.slideSuggSits}
      />
      <RatingSliderDisplay
        title={'Alcohol Abuse'}
        value={sliders.slideAlcohol}
      />
      <RatingSliderDisplay
        title={'Sensuality'}
        value={sliders.slideSensuality}
      />
      <RatingSliderDisplay
        title={'Immoral Behavior'}
        value={sliders.slideImmoBehav}
      />
      <RatingSliderDisplay
        title={'Religious Content'}
        value={sliders.slideReligCon}
      />
      <RatingSliderDisplay
        title={'Inappropriate Images'}
        value={sliders.slideInappImg}
      />
    </div>
  );
};
