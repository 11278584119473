import { h, FunctionalComponent } from 'preact';
import * as style from './bookWithRatingDisplay.scss';
import { InputText } from '../../Components/InputText/InputText';
import { BookRatingDisplay } from '../../Components/types';

interface SideLeftDisplayProps {
  fields: BookRatingDisplay;
}

export const SideLeftDisplay: FunctionalComponent<SideLeftDisplayProps> = ({
  fields,
}) => {
  return (
    <div class={style.sideLeftDisplayComponentWrap}>
      <div class={style.blurbLeft}>Book Details:</div>
      <div class={style.leftSideFlex}>
        <div class={style.fieldLabel}>ISBN:</div>
        <div class={style.fieldInput}>
          <InputText value={fields.isbn} disabled={true} />
        </div>
      </div>
      <div class={style.leftSideFlex}>
        <div class={style.fieldLabel}>Author:</div>
        <div class={style.fieldInput}>
          <InputText value={fields.author} disabled={true} />
        </div>
      </div>
      <div class={style.leftSideFlex}>
        <div class={style.fieldLabel}>Title:</div>
        <div class={style.fieldInput}>
          <InputText value={fields.title} disabled={true} />
        </div>
      </div>
      <div class={style.leftSideFlex}>
        <div class={style.fieldLabel}>Publisher:</div>
        <div class={style.fieldInput}>
          <InputText value={fields.publisher} disabled={true} />
        </div>
      </div>
      <div class={style.leftSideFlex}>
        <div class={style.fieldLabel}>Year:</div>
        <div class={style.fieldInput}>
          <InputText value={fields.year} disabled={true} />
        </div>
      </div>
      <div class={style.leftSideFlex}>
        <div class={style.fieldLabel}>Genre:</div>
        <div class={style.fieldInput}>
          <InputText value={fields.genre} disabled={true} />
        </div>
      </div>
      <div class={style.leftSideFlex}>
        <div class={style.fieldLabel}>Format:</div>
        <div class={style.fieldInput}>
          <InputText
            value={
              fields.format === 'a'
                ? 'Audiobook'
                : fields.format === 'h'
                ? 'Hardcover'
                : fields.format === 'p'
                ? 'Paperback'
                : fields.format === 'e'
                ? 'eBook'
                : 'Other'
            }
            disabled={true}
          />
        </div>
      </div>
      <div class={style.leftSideFlex}>
        <div class={style.fieldLabel}>Tag:</div>
        <div class={style.fieldInput}>
          <InputText value={fields.tags} disabled={true} />
        </div>
      </div>
    </div>
  );
};
