import { h, FunctionalComponent } from 'preact';
import * as style from './rateBook.scss';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { SliderKey } from './RateBook';

interface RatingSliderProps {
  onSlideSet(name: SliderKey, value: number | number[]): void;
  laKey: SliderKey;
  title: string;
  value: number | number[];
}

const marks = [
  {
    value: 0,
    // label: '0',
  },
  {
    value: 1,
    // label: '1',
  },
  {
    value: 2,
    // label: '2',
  },
  {
    value: 3,
    // label: '3',
  },
];

const PrettoSlider = withStyles({
  root: {
    color: '#0073ea',
    height: 2,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    borderRadius: 'unset',
    marginTop: -6,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 2,
    borderRadius: 0,
  },
  rail: {
    height: 2,
    borderRadius: 0,
  },
})(Slider);

export const RatingSlider: FunctionalComponent<RatingSliderProps> = ({
  laKey,
  title,
  value,
  onSlideSet,
}) => {
  const handleChange = (e: any, value: number | number[]) => {
    onSlideSet(laKey, value);
  };
  return (
    <div class={style.sliderWrap}>
      <div class={style.title}>{title}:</div>
      <div class={style.slide}>
        <PrettoSlider
          defaultValue={0}
          valueLabelDisplay='auto'
          onChangeCommitted={handleChange}
          value={value}
          step={1}
          marks={marks}
          min={0}
          max={3}
        />
      </div>
    </div>
  );
};
