/**
 * JS Template literal function which removes all spaces in passed variables and replaces them with '_'
 * @param {String} strings
 * @param {*} values
 * @return {String} Formatted version of the input template literal
 */
export function noSpaces (strings: TemplateStringsArray, ...values: string[]): string {
  return strings.reduce(function (combined, string, i) {
    return combined + string + (values[i] ? String(values[i]).replace(/ /g, '_') : '');
  }, '');
}

/**
 * JS Template literal function which removes all underscores in passed variables and replaces them with spaces
 * @param {String} strings
 * @param {*} values
 * @return {String} Formatted version of the input template literal
 */
export function presentation (strings: TemplateStringsArray, ...values: string[]): string {
  return strings.reduce(function (combined, string, i) {
    return combined + string + (values[i] ?
      String(values[i]).split('_').map((d) => d[0].toUpperCase() + d.substr(1)).join(' ') : '');
  }, '');
}

/**
 * JS Template literal function which all special characters for creating variable class names.
 * Also will check for a leading number which also causes errors in class selectors
 * @param {String} strings
 * @param {*} values
 * @return {String} Formatted version of the input template literal
 */
export function forClass (strings: TemplateStringsArray, ...values: any[]): string {
  return strings.reduce(function (combined, string, i) {
    const value = (string.charAt(string.length - 1) === ' ' || string === '') &&
            !isNaN(values[i].charAt(0)) ? `c_${values[i]}` : values[i] ? values[i] : '';
    return combined + string + String(value).replace(/[\W]+/g, '');
  }, '');
}

/**
 * JS Template literal function which capitalizes the first character of each passed in value
 * @param {String} strings
 * @param {*} values
 * @return {String} Formatted version of the input template literal
 */
export function titleCase (strings: TemplateStringsArray, ...values: string[]): string {
  return strings.reduce(function (combined, string, i) {
    return combined + string + (values[i] ? String(values[i][0].toUpperCase() + values[i].substr(1)) : '');
  }, '');
}
