import { h, FunctionalComponent } from 'preact';
import * as style from './bookWithRatingDisplay.scss';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

interface RatingSliderDisplayProps {
  title: string;
  value: number | number[];
}

const marks = [
  {
    value: 0,
    // label: '0',
  },
  {
    value: 1,
    // label: '1',
  },
  {
    value: 2,
    // label: '2',
  },
  {
    value: 3,
    // label: '3',
  },
];

const PrettoSlider = withStyles({
  root: {
    color: '#999',
    height: 2,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    borderRadius: 'unset',
    marginTop: -12,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 2,
    borderRadius: 0,
  },
  rail: {
    height: 2,
    borderRadius: 0,
  },
})(Slider);

export const RatingSliderDisplay: FunctionalComponent<RatingSliderDisplayProps> = ({
  title,
  value,
}) => {
  return (
    <div class={style.sliderWrap}>
      <div class={style.title}>{title}:</div>
      <div class={style.slide}>
        <PrettoSlider
          defaultValue={0}
          valueLabelDisplay='auto'
          value={value}
          step={1}
          marks={marks}
          min={0}
          max={3}
        />
      </div>
    </div>
  );
};
