/*
  https://css-tricks.com/the-difference-between-throttling-and-debouncing/
  Debounce = during input, call func at most once every __ms
  Eg: const debounceMouseMove = debounce(handleMouseMove, 60);
  Throttle = wait until __ms has passed since last input before calling func
  Eg: const debounceMouseMove = throttle(handleMouseMove, 60, true); (true triggers first call at beginning; false after the first delay)
*/
export const debounce = <T extends (this: T, ...args: any[]) => void>(func: T, delay: number) => {
  let debounceTimer: number;
  return function (this: T, ...args: Parameters<T>): void {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(() => func.apply(this, args), delay);
  } as T;
};

export function throttle<T extends (this: T, ...args: any[]) => void>(callback: T, wait: number, immediate = false) {
  let timeout: number | undefined;
  let initialCall = true;

  return function (this: T, ...args: Parameters<T>) {
    const callNow = immediate && initialCall;
    const next = () => {
      callback.apply(this, args);
      window.clearTimeout(timeout);
      timeout = undefined;
    };

    if (callNow) {
      initialCall = false;
      next();
    }

    if (!timeout) {
      timeout = window.setTimeout(next, wait);
    }
  } as T;
}
