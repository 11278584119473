import { h, FunctionalComponent } from 'preact';
import * as style from './mainWelBlurb.scss';

interface MainWelcomeBlurbProps {}

export const MainWelcomeBlurb: FunctionalComponent<MainWelcomeBlurbProps> = () => {
  return (
    <div class={style.welblurbWrap}>
      <div class={style.welcome}>Welcome to iberry</div>
      <div class={style.blurb}>Your library community content rating system</div>
    </div>
  );
};
