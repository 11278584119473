import { h, FunctionalComponent } from 'preact';
import * as style from './bookWithRatingDisplay.scss';
import { BookRatingDisplay } from '../../Components/types';
import { get } from '../../../utils/ajax';
import { useState, useEffect } from 'preact/hooks';
import { SideLeftDisplay } from './SideLeftDisplay';
import { SideRightDisplay } from './SideRightDisplay';
import { TextArea } from '../../Components/TextArea/TextArea';

/* Called from App.tsx, per route /book/:bid  */

interface BookWithRatingDisplayProps {
  bookID: number;
  doClose(): void;
}

export const BookWithRatingDisplay: FunctionalComponent<BookWithRatingDisplayProps> = ({
  bookID,
  doClose,
}) => {
  const [bookData, setBookData] = useState<BookRatingDisplay | undefined>(
    undefined
  );
  useEffect(() => {
    const bid = bookID;
    get<BookRatingDisplay>(`/displayBookWithRating?bid=${bid}`)
      .then((bookData) => {
        // console.log(`BookWithRatingDisplay.tsx - bookData: `, bookData);
        setBookData(bookData);
      })
      .catch(() => {
        console.log(
          `Error: Oops. We had an error condition fetching the data for this book title.`
        );
      });
  }, [bookID]);
  return (
    <div class={style.bookWithRatingWrapper}>
      {bookData !== undefined && (
        <div class={style.formWrapper}>
          <div class={style.closeX} onClick={doClose}>
            {' '}
            x{' '}
          </div>
          <div class={style.rateForm}>
            <div class={style.title}>View a Book Rating</div>
            <div class={style.formBody}>
              <div class={style.imgBtnDiv}>
                <div class={style.theCovPic}>
                  <img src={`/img/cover/cov-${bookID}.jpg`} />
                </div>
                <div class={style.theBtn}>
                  <button class={style.btnClose} onClick={doClose}>
                    Close
                  </button>
                </div>
              </div>
              <div class={style.sideLeft}>
                <SideLeftDisplay fields={bookData} />
                <div class={style.userComment}>
                  <TextArea
                    rows={6}
                    value={bookData.usercomment}
                    disabled={true}
                  />
                </div>
              </div>
              <div class={style.sideRight}>
                <SideRightDisplay
                  sliders={bookData.rcats}
                  rating={bookData.rating}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
