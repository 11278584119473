import { h, FunctionalComponent } from 'preact';
import * as style from './rateBook.scss';
import { Select } from '../../Components/Select/Select';
import { InputText } from '../../Components/InputText/InputText';
import { FieldKey } from './RateBook';

interface SideLeftProps {
  onUpdateData(fieldName: FieldKey, value: string): void;
  fields: { [key: string]: string };
  selects: { [key: string]: string[] };
}

export const SideLeft: FunctionalComponent<SideLeftProps> = ({
  onUpdateData,
  fields,
  selects,
}) => {
  const handleUpdateData = (fieldName: FieldKey, value: string) => {
    onUpdateData(fieldName, value);
    return value;
  };
  // const updateSelectClass = (ctrlName:string) => {
  //   if (ctrlName === 'genre'){
  //     genreClass = style.colorBlack;
  //   }
  // }
  return (
    <div class={style.sideLeftComponentWrap}>
      <div class={style.blurbLeft}>Book Details:</div>
      <div class={style.blurbLeft2}>*Fields are Required:</div>
      <InputText
        onType={(d) => handleUpdateData('isbn', d)}
        value={fields.isbn}
        placeholder={'ISBN*'}
      />
      <InputText
        onType={(d) => handleUpdateData('author', d)}
        value={fields.author}
        placeholder={'Author*'}
      />
      <InputText
        onType={(d) => handleUpdateData('title', d)}
        value={fields.title}
        placeholder={'Title*'}
      />
      <InputText
        onType={(d) => handleUpdateData('publisher', d)}
        value={fields.publisher}
        placeholder={'Publisher'}
      />
      <InputText
        onType={(d) => handleUpdateData('year', d)}
        value={fields.year}
        placeholder={'Year'}
      />
      <Select
        onChange={(d) => handleUpdateData('genre', d)}
        class={
          fields.genre !== 'Choose Genre: *' ? style.selectValueChanged : ''
        }
        value={fields.genre}
        nodefault={true}
        options={selects.selectGenre}
      ></Select>
      <Select
        onChange={(d) => handleUpdateData('format', d)}
        class={
          fields.format !== 'Choose Format: *' ? style.selectValueChanged : ''
        }
        value={fields.format}
        nodefault={true}
        options={selects.selectFormat}
      ></Select>
      <Select
        onChange={(d) => handleUpdateData('tags', d)}
        class={
          fields.tags !== 'Choose a Tag: *' ? style.selectValueChanged : ''
        }
        value={fields.tags}
        nodefault={true}
        options={selects.selectTags}
      ></Select>
    </div>
  );
};
