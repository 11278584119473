import { h, FunctionalComponent } from 'preact';
import * as style from './mainWelSx.scss';
import { InputText } from '../../../Components/InputText/InputText';
import { Button } from '../../../Components/Button/Button';
import { useState } from 'preact/hooks';
import { Redirect } from 'wouter-preact';

interface MainWelcomeSearchProps {
  onSubmit(sxText: string): void;
}

export const MainWelcomeSearch: FunctionalComponent<MainWelcomeSearchProps> = ({
  onSubmit,
}) => {
  const [sxText, setSxText] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    onSubmit(sxText);
    setSubmitted(true);
  };
  return (
    <div class={style.searchDiv}>
      {submitted && <Redirect to='/books' />}
      <InputText
        class={style.inputSx}
        onType={setSxText}
        onConfirm={handleSubmit}
        value={sxText}
        placeholder={'Search book title'}
      />
      <Button class={style.btn2d} onClick={handleSubmit}>
        search
      </Button>
    </div>
  );
};
