import { h, Component } from 'preact';
import * as style from './books.scss';
import { BooksSideTagDrawer } from '../../Components/BooksSideTagDrawer/BooksSideTagDrawer';
import { BooksTopLogoAndSearch } from '../../Components/BooksTopLogoAndSearch/BooksTopLogoAndSearch';
import { BooksPageResultsBar } from '../../Components/BooksPageResultsBar/BooksPageResultsBar';
import { BooksLeftSideAdvancedSearch } from '../../Components/BooksLeftSideAdvancedSearch/BooksLeftSideAdvancedSearch';
import { BooksResults } from '../../Components/BooksResults/BooksResults';
import { BookTitle, AdvSx, BtnAction } from '../../Components/types';
import { get, URLWithParams } from '../../../utils/ajax';
import { Pagination } from '../../pagination/paginationStore';
import { createPortal } from 'preact/compat';

/* Creates the BOOKS page - Uses BookResults.tsx to display the list of books */

interface BooksProps {
  sxText: string | undefined;
}
interface BooksState {
  error?: string;
  books?: BookTitle[];
  advSxObj?: AdvSx;
  showBookCover: boolean;
  currentPage: number;
  itemsPerPage: number;
}
export class Books extends Component<BooksProps, BooksState> {
  public constructor(props: BooksProps) {
    super(props);
    let currPage: string | number | null = sessionStorage.getItem('pagination');
    if (!currPage) {
      currPage = '1';
    }
    this.state = {
      showBookCover: true,
      currentPage: +currPage,
      itemsPerPage: 15,
    };
  }
  public render(
    { sxText }: BooksProps,
    { books, showBookCover, currentPage, itemsPerPage }: BooksState
  ) {
    return (
      <div class={style.booksWrapper}>
        {createPortal(
          <BooksTopLogoAndSearch
            sxText={sxText}
            onSubmit={this.handleSearch}
          />,
          document.body
        )}
        <BooksSideTagDrawer onSubmit={this.handleAdvSx} />

        <div class={style.pageWrapper}>
          <Pagination.Provider value={{ currentPage, itemsPerPage }}>
            <div class={style.resultsWrapper}>
              <BooksPageResultsBar
                numBooks={books?.length}
                resultBarBtn={this.handleResultBarBtn}
                onPageChange={this.updateCurrentPage}
                onItemsPPChange={this.updateItemsPerPage}
              />
              <BooksLeftSideAdvancedSearch onSubmit={this.handleAdvSx} />
              <BooksResults books={books} showBookCover={showBookCover} />
            </div>
          </Pagination.Provider>
        </div>
      </div>
    );
  }

  public componentDidMount() {
    const sxText = this.props.sxText;
    if (sxText === undefined || sxText === '') {
      get<BookTitle[]>('/allBooks')
        .then((books) => {
          this.setState({ books });
        })
        .catch(() => {
          this.setState({
            error:
              'We encountered a problem getting current books. Please try again later.',
          });
        });
    } else {
      this.handleSearch(sxText);
    }
  }
  private handleSearch = (sxText: string) => {
    get<BookTitle[]>(`/sxBooks?sxText=${sxText}`)
      .then((books) => {
        this.setState({ books });
      })
      .catch(() => {
        this.setState({
          error:
            'We encountered a problem searching books. Please try again later.',
        });
      });
  };
  private handleAdvSx = (advSxObj: AdvSx) => {
    get<BookTitle[]>(URLWithParams('/advSxBooks', advSxObj))
      .then((books) => {
        this.setState({ books, advSxObj });
      })
      .catch(() => {
        this.setState({
          error:
            'We encountered a problem searching books. Please try again later.',
        });
      });
  };
  private handleResultBarBtn = (btnAction: BtnAction) => {
    if (btnAction.action === 'reset') {
      this.handleAdvSx({});
    } else if (btnAction.action === 'showBookCover') {
      this.setState({ showBookCover: btnAction.param as boolean });
    }
  };
  private updateCurrentPage = (value: number) => {
    this.setState({ currentPage: value });
  };
  private updateItemsPerPage = (value: number) => {
    const page = this.state.currentPage;
    const numBooks = this.state.books?.length;
    const totalPages =
      numBooks !== undefined ? Math.ceil(numBooks / (value || 15)) : 1;
    const laPage = page < 1 ? 1 : page > totalPages ? totalPages : page;
    this.setState({ itemsPerPage: value, currentPage: laPage });
  };
}
