import { h, Component } from 'preact';
import * as style from './profile.scss';

interface ProfileProps {}

interface ProfileState {}

export class Profile extends Component<ProfileProps, ProfileState> {
  public render({}: ProfileProps, {}: ProfileState) {
    return (
      <div class={style.profileWrap}>
        {' '}
        <div class={style.pageWrapper}>
          <div class={style.body}>Profile page is Coming Soon . . .</div>
        </div>
      </div>
    );
  }
}
