import { h, Component } from 'preact';
import * as style from './rateSystem.scss';

interface RatingSystemProps {}

interface RatingSystemState {}

export class RatingSystem extends Component<
  RatingSystemProps,
  RatingSystemState
> {
  public render({}: RatingSystemProps, {}: RatingSystemState) {
    return (
      <div class={style.rateSystemWrap}>
        <div class={style.pageWrapper}>
          <div class={style.pageForm}>
            <div class={style.title}>The iBerry Rating System</div>
            <div class={style.topTrifold}>
              <div class={`${style.foldLeft} ${style.triFolds}`}>
                <div class={style.trifoldTitle}>4 minutes</div>
                <ul>
                  <li>Create Account</li>
                  <li>Log In</li>
                  <li>Rate a Book</li>
                  <li>Vote on ratings</li>
                </ul>
              </div>
              <div class={`${style.foldMiddle} ${style.triFolds}`}>
                <div class={style.trifoldTitle}>5 rating levels</div>
                <div class={`${style.levelDiv} ${style.levelG}`}>
                  <div class={style.leftLetter}>G</div>
                  <div class={style.rightRating}>
                    <div class={style.ratingTop}>GENERAL READERS</div>
                    <div class={style.ratingBot}>APPROPRIATE FOR ALL AGES</div>
                  </div>
                </div>
                <div class={style.levelBigWrap}>
                  <div class={`${style.levelDiv} ${style.levelPG}`}>
                    <div class={style.leftLetter}>PG</div>
                    <div class={style.rightRating}>
                      PARENTAL GUIDANCE SUGGESTED
                    </div>
                  </div>
                  <div class={style.ratingBotPG}>
                    SOME MATERIAL MAY NOT BE SUITABLE FOR CHILDREN
                  </div>
                </div>
                <div class={style.levelBigWrap}>
                  <div class={`${style.levelDiv} ${style.levelPG13}`}>
                    <div class={style.leftLetter}>PG-13</div>
                    <div class={style.rightRating}>
                      PARENTS STRONGLY CAUTIONED
                    </div>
                  </div>
                  <div class={style.ratingBotPG13}>
                    SOME MATERIAL MAY BE INAPPROPRIATE FOR CHILDREN UNDER 13
                  </div>
                </div>
                <div class={`${style.levelDiv} ${style.levelM}`}>
                  <div class={style.leftLetter}>M</div>
                  <div class={style.rightRating}>
                    <div class={style.ratingTop}>MATURE CONTENT</div>
                    <div class={style.ratingBot}>NOT RECOMMENDED UNDER 18</div>
                  </div>
                </div>
                <div class={`${style.levelDiv} ${style.levelR}`}>
                  <div class={style.leftLetter}>R</div>
                  <div class={style.rightRating}>
                    <div class={style.ratingTop}>RESTRICTED</div>
                    <div class={style.ratingBot}>NOT FOR ANYONE UNDER 18</div>
                  </div>
                </div>
              </div>
              <div class={`${style.foldRight} ${style.triFolds}`}>
                <div class={style.trifoldTitle}>6 required fields</div>
                <ul>
                  <li>ISBN</li>
                  <li>Title</li>
                  <li>Author</li>
                  <li>Genre</li>
                  <li>Format</li>
                  <li>Tag</li>
                </ul>
              </div>
            </div>
            <div class={style.section}>
              <div class={style.heading}>How Does It Work?</div>
              <div class={style.article}>
                <p>
                  Readers access iberry for quick content ratings of books they
                  intend to read. The primary idea is to ensure that readers are
                  exposed only to the type of content to which they choose to be
                  exposed.
                </p>
                <p>
                  When you rate a book, you are setting warning flags to future
                  readers regarding questionable content. There are three levels
                  of warning, but they are not sharply defined. As a content
                  rater, you make the decision as to whether a content category
                  is "low", "medium" or "high". Several factors will affect the
                  levels: severity, frequency, and how graphic the description.
                  Everybody has their own relative morality so there will be
                  grey areas between readers as to what is acceptable -- but
                  this is where iberry's rating scale comes in. As more iberry
                  users approve and disapprove a rating, that rating will
                  self-adjust to its appropriate level.
                </p>
                <p>
                  After completing the essential book information fields
                  (author, title, isbn, etc), you will set the content sliders
                  to reflect the severity of the book's content for each
                  category listed.
                </p>
                <p>
                  Book ratings are automatically determined based on the slider
                  settings. Leaving all sliders at zero will result in a G
                  rating.
                </p>
                <p>
                  Once a rating is submitted, it is set. To later adjust your
                  own rating, you must use the downvote procedure.
                </p>
              </div>
            </div>
            <div class={style.section}>
              <div class={style.heading}>Whose Morality?</div>
              <div class={style.article}>
                <p>
                  Our pluralistic society now accepts all moral frameworks and
                  generally adheres to the view that morality is relative: there
                  are no moral absolutes; what is right for you is right for
                  you, but what is right for me is right for me.
                </p>
                <p>
                  Few of us, though, would extend this viewpoint to others with
                  respect to our own children. Consider the universal abhorrence
                  to pedophiliac sports coaches, teachers, religious leaders,
                  etc. Would you leave your young child in such a person's care?
                  Would you think their activities are okay because "it's right
                  for them"? No. Instead we are all horrified at what these
                  people do. But if morality is truly relative, then how can we
                  justify this revulsion? In our everyday world, morality is not
                  relative. There are moral absolutes. Some behaviours are
                  always wrong; some behaviours are just right.{' '}
                  <span id='aisha' class='clickable' style='display:none;'>
                    [1]
                  </span>
                </p>
                <p>
                  An increasing number of adults are seeing the gaping
                  deficiencies of the "right for me / right for you" philosophy
                  in our own lives and with respect to our own minds. Everything
                  we see, hear, read and otherwise absorb stays with us and,
                  over time, can affect or influence our future behaviour. Of
                  course, we are rarely conscious of this happening. Since we
                  all want a better, and more secure, life for our children than
                  we had ourselves, helping them to guard their minds against
                  exposure to the wrong sort of information too early is an
                  important element in protecting and parenting.
                </p>
                <p>
                  In assigning book ratings, we appeal to you, our users and
                  fellow readers, to assign ratings based upon what you would
                  want to know when deciding whether a book is acceptable
                  reading for your own eight-year-old child.
                </p>
                <p>
                  This website is not intended for everyone. If you disagree
                  with the views expressed above, then this website is not for
                  you. This site does not review books, it only rates them with
                  respect to content maturity level.
                </p>
              </div>
            </div>
            <div class={style.section}>
              <div class={style.heading}>Definitions and Explanations</div>
              <div class={style.article}>
                <h2>Create An Account</h2>
                <p>
                  It is not necessary to create an account if you only wish to
                  use iberry. However, if you wish to enter new book ratings, or
                  to comment on existing ratings, you must have an account. All
                  you need is your name, email and password.
                </p>
                <h2>Login</h2>
                <p>
                  Please log in to participate in the rating / evaluation of
                  books.
                </p>
                <h2>Rate A Book</h2>
                <p>
                  When you rate a book, you assist future readers in choosing
                  reading material. The more accurate the information you
                  provide, the higher your score will be on iberry -- and the
                  greater your contribution to the reading community.
                </p>
                <h2>Vote On Other Ratings</h2>
                <p>
                  By approving / disapproving other book ratings, you help to
                  fine-tune the rating system. Remember that the up/down arrows
                  are for approving the rating NOT THE BOOK. To indicate how
                  much you liked the book, use the stars. You can add your star
                  rating by clicking on the book title.
                </p>
                <h2>ISBN</h2>
                <p>
                  International Standard Book Number. Every book published since
                  1965 has been issued an ISBN number, which is a unique serial
                  number for that publication. The ISBN allows us to quickly
                  locate a specific, even amongst books with identical titles --
                  or even multiple publishings of the same book. The ISBN is
                  usually found on the back cover, or on the publisher's page at
                  the beginning of the book.
                </p>
                <h2>Title</h2>
                <p>
                  Because the ISBN will precisely identify a book, it is only
                  necessary to enter the main title, not any sub-titles.
                </p>
                <h2>Author</h2>
                <p>
                  Only the first author listed on the book's cover is required.
                </p>
                <h2>Format</h2>
                <p>
                  The format is important because the content is sometimes
                  changed between formats. For example, swearing is often
                  removed from audio book presentations. Similarly, eBooks may
                  have illustrations and photographs removed.
                </p>
                <h2>Tag</h2>
                <p>
                  A tag is very similar to a genre, but is useful for a
                  secondary classification. For example, a cook book falls into
                  the how-to genre, but both cook books and books about
                  cooks/cooking can be found under the food tag.
                </p>
                <h2>Genre</h2>
                <p>
                  A book's genre is the type of content the book contains:
                  romance, history, western, etc. Please use the chart below if
                  you are unsure which genre category to choose.
                </p>
                <div class={style.tableDiv}>
                  <table>
                    <tbody>
                      <tr>
                        <th>Genre</th>
                        <th>Description</th>
                      </tr>
                      <tr>
                        <td>essay</td>
                        <td>
                          Short literary composition that reflects the author's
                          view.
                        </td>
                      </tr>
                      <tr>
                        <td>fable</td>
                        <td>
                          Narration demonstrating a useful truth, especially in
                          which animals speak as humans; legendary, supernatural
                          tale
                        </td>
                      </tr>
                      <tr>
                        <td>fairy-tale</td>
                        <td>
                          Story about fairies or other magical creatures,
                          usually for children
                        </td>
                      </tr>
                      <tr>
                        <td>fantasy</td>
                        <td>
                          Fiction with strange or other worldly settings or
                          characters; fiction which invites suspension of
                          reality
                        </td>
                      </tr>
                      <tr>
                        <td>folklore</td>
                        <td>
                          The songs, stories, myths, and proverbs of a people or
                          "folk" as handed down by word of mouth
                        </td>
                      </tr>
                      <tr>
                        <td>horror</td>
                        <td>
                          Fiction in which events evoke a feeling of dread and
                          sometimes fear in both the characters and the reader
                        </td>
                      </tr>
                      <tr>
                        <td>humor</td>
                        <td>
                          Usually a fiction full of fun, fancy, and excitement,
                          meant to entertain and sometimes cause intended
                          laughter; but can be contained in all genres
                        </td>
                      </tr>
                      <tr>
                        <td>legend</td>
                        <td>
                          Story, sometimes of a national or folk hero, that has
                          a basis in fact but also includes imaginative material
                        </td>
                      </tr>
                      <tr>
                        <td>metafiction</td>
                        <td>
                          Uses self-reference to draw attention to itself as a
                          work of art, exposing the "truth" of a story (known as
                          romantic irony in the context of Romantic literature)
                        </td>
                      </tr>
                      <tr>
                        <td>mystery</td>
                        <td>
                          Fiction dealing with the solution of a crime or the
                          unraveling of secrets
                        </td>
                      </tr>
                      <tr>
                        <td>mythology</td>
                        <td>
                          Legend or traditional narrative, often based in part
                          on historical events, that reveals human behavior and
                          natural phenomena by its symbolism; often pertaining
                          to the actions of the gods
                        </td>
                      </tr>
                      <tr>
                        <td>poetry</td>
                        <td>
                          Verse and rhythmic writing with imagery that creates
                          emotional responses
                        </td>
                      </tr>
                      <tr>
                        <td>science fiction</td>
                        <td>
                          Story based on impact of actual, imagined, or
                          potential science, usually set in the future or on
                          other planets
                        </td>
                      </tr>
                      <tr>
                        <td>historical fiction</td>
                        <td>
                          Story with fictional characters and events in a
                          historical setting
                        </td>
                      </tr>
                      <tr>
                        <td>tall tale</td>
                        <td>
                          Humorous story with blatant exaggerations, swaggering
                          heroes who do the impossible with nonchalance
                        </td>
                      </tr>
                      <tr>
                        <td>biography</td>
                        <td>
                          Narrative of a person's life. A true story about a
                          real person
                        </td>
                      </tr>
                      <tr>
                        <td>commercial fiction</td>
                        <td>
                          Written with the intent of reaching as wide an
                          audience as possible. Commercial fiction is sometimes
                          called genre fiction because books of this type often
                          fall into categories, such as western, gothic,
                          romance, historical, mystery
                        </td>
                      </tr>
                      <tr>
                        <td>fictional biography</td>
                        <td>
                          Biography of a real person but exagerating the events
                          of a person's life by expanding with imagined scenes
                          and dialogue. The writer of fictional biographies
                          strives to make it clear that the story is fiction and
                          not history.
                        </td>
                      </tr>
                      <tr>
                        <td>mainstream fiction</td>
                        <td>
                          Fiction that covers popular novel categories such as
                          mystery, romance or adventure. Tells stories about
                          people and their conflicts with greater depth of
                          characterization and background than the more narrowly
                          focused genres.
                        </td>
                      </tr>
                      <tr>
                        <td>narrative nonfiction</td>
                        <td>
                          Factual information presented in a format which tells
                          a story.
                        </td>
                      </tr>
                      <tr>
                        <td>psychological novel</td>
                        <td>
                          A narrative that emphasizes the mental and emotional
                          aspects of its characters, focusing on motivations and
                          mental activities rather than on exterior events. Less
                          concern with relating what happened than exploring why
                          it happened.
                        </td>
                      </tr>
                      <tr>
                        <td>roman a clef</td>
                        <td>
                          French term for <i>novel with a key</i>. Incorporates
                          real people and events into the story under the guise
                          of fiction.
                        </td>
                      </tr>
                      <tr>
                        <td>techno-thriller</td>
                        <td>
                          Uses many of the same elements as the thriller, with
                          one major difference. In techno-thrillers, technology
                          becomes a major character.{' '}
                        </td>
                      </tr>
                      <tr>
                        <td>textbook</td>
                        <td>
                          Authoritative and detailed factual description of a
                          topic
                        </td>
                      </tr>
                      <tr>
                        <td>reference</td>
                        <td>
                          Dictionary, thesaurus, encyclopedia, almanac, atlas,
                          etc
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
