import { h, Component } from 'preact';
import * as style from './kids.scss';

interface KidsProps {}

interface KidsState {}

export class Kids extends Component<KidsProps, KidsState> {
  public render({}: KidsProps, {}: KidsState) {
    return (
      <div class={style.kidsWrap}>
        <div class={style.pageWrapper}>
          <div class={style.body}>Kids is Coming Soon . . .</div>
        </div>
      </div>
    );
  }
}
