import { h, Component } from 'preact';
import * as style from './authors.scss';

interface AuthorsProps {}

interface AuthorsState {}

export class Authors extends Component<AuthorsProps, AuthorsState> {
  public render({}: AuthorsProps, {}: AuthorsState) {
    return (
      <div class={style.authWrap}>
        <div class={style.pageWrapper}>
          <div class={style.body}>Authors page is Coming Soon . . .</div>
        </div>
      </div>
    );
  }
}
