import { h, Component } from 'preact';
import * as style from './members.scss';
import { UserData, Authentication } from '../../store/store';

interface MembersProps {}

interface MembersState {}

export class Members extends Component<MembersProps, MembersState> {
  public static contextType = Authentication;
  public render({}: MembersProps, {}: MembersState) {
    const { authenticated } = this.context as UserData;
    return authenticated ? (
      <div class={style.membersWrap}>
        <div class={style.pageWrapper}>
          <div class={style.body}>Members page is Coming Soon . . .</div>
        </div>
      </div>
    ) : (
      <div class={style.membersWrap}>
        <div class={style.pageWrapper}>
          <div class={style.body}>Members is Coming Soon . . .</div>
        </div>
      </div>
    );
  }
}
