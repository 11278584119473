import { createContext } from 'preact';

export interface PaginationData {
  currentPage: number;
  itemsPerPage: number;
}

export const Pagination = createContext<PaginationData>({
  currentPage: 1,
  itemsPerPage: 5,
});
